import classnames from 'classnames/bind'
import { GlobalTextPreset } from '~/@types/text-preset'

import { RichTextBlock } from '~/components/Abstracts/RichText'
import RichText from '~/components/UI/RichText'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type WysiwygProps = {
  className?: string
  content?: RichTextBlock
}

function Wysiwyg({ className, content }: WysiwygProps) {
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.RichTextEditorial,
  })
  return (
    <div className={cx(css.Wysiwyg, className)}>
      <RichText className={cx(css.content, textStyle)} render={content} />
    </div>
  )
}

Wysiwyg.defaultProps = {}

export default Wysiwyg

import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { Link, LinkProps } from '@unlikelystudio/react-abstract-components'

import Image, { imageLoaderStoryblok } from '~/components/UI/Image'
import { CARD_IMAGE_LOADER, CardImage } from '~/components/UI/ProductCard/types'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type ProductPinTagProps = {
  className?: string
  designer?: string
  name?: string
  link?: LinkProps
  price?: string
  image?: CardImage
}

function ProductPinTag({
  className,
  designer,
  name,
  link,
  price,
  image,
}: ProductPinTagProps) {
  const tagStyle = useStyle({
    backgroundColor: GlobalThemeColors.White,
  })
  const designerStyle = useStyle({
    textPreset: GlobalTextPreset.Label12SangBleu,
    textStyling: GlobalTextStyling.Capitalize,
  })
  const priceStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Grotesk,
  })

  const nameStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Grotesk,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const hasContent = designer || price || name

  const content = (
    <>
      {image && (
        <Image
          className={cx(css.image)}
          layout="fill"
          objectFit="cover"
          sizesFromBreakpoints={[{ ratio: 3 / 10 }]}
          loader={
            image.loaderType === CARD_IMAGE_LOADER.STORYBLOK
              ? imageLoaderStoryblok
              : null
          }
          {...image.data}
        />
      )}
      {hasContent && (
        <div className={cx(css.content)}>
          <div className={cx(css.topLine)}>
            {designer && (
              <div className={cx(css.designer, designerStyle)}>{designer}</div>
            )}
            {price && <div className={cx(css.price, priceStyle)}>{price}</div>}
          </div>
          {name && <div className={cx(css.name, nameStyle)}>{name}</div>}
          <div className={cx(css.mockButton)}>[+]</div>
        </div>
      )}
    </>
  )

  return (
    <div className={cx(css.ProductPinTag, className, tagStyle)}>
      {link ? (
        <Link className={cx(css.link)} {...link}>
          {content}
        </Link>
      ) : (
        <div className={cx(css.container)}>{content}</div>
      )}
    </div>
  )
}

ProductPinTag.defaultProps = {}

export default ProductPinTag

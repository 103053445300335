import classnames from 'classnames/bind'
import React, { CSSProperties, useState } from 'react'
import { GlobalThemeColors } from '~/@types/colors'

import {
  IMAGE_SIZE,
  IMAGE_SIZES,
  ImageWithLabelProps,
} from '~/components/Slices/ImageWithLabel'
import { ProductPin } from '~/components/Slices/ProductsPinsPush'
import Image, { ImageProps } from '~/components/UI/Image'
import PinMarker from '~/components/UI/PinMarker'

import numberAsBoolean from '~/utils/number-as-boolean'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type ProductsPinsPushProps = {
  className?: string
  pins?: ProductPin[]
  background?: ImageProps
  withActiveLayout?: boolean
  priority?: boolean
  theme?: typeof GlobalThemeColors.Black | typeof GlobalThemeColors.White
  layout?: ImageWithLabelProps['layout']
}

export type PinPosition = {
  x: number
  y: number
}

export type PinProps = {
  className?: string
  position: PinPosition
}

function ProductPins({
  className,
  pins,
  background,
  withActiveLayout,
  priority,
  theme,
  layout,
}: ProductsPinsPushProps) {
  const [activePin, setActivePin] = useState<number>(null)
  const [loadedBackground, setLoadedBackground] = useState<boolean>(false)
  const containerStyle: CSSProperties = {
    position: 'relative',
    width: '100%',
  }
  const pinsElement =
    pins?.map((pin, index) => (
      <PinMarker key={`marker_${index}`} theme={theme} {...pin} />
    )) ?? null

  return (
    <div className={cx(className, css.ProductsPins)} style={containerStyle}>
      {background && (
        <Image
          onClick={() => {
            setActivePin(null)
          }}
          onLoadingComplete={() => setLoadedBackground(true)}
          priority={priority}
          {...background}
          layout={
            layout
              ? layout === IMAGE_SIZE.LARGE
                ? 'responsive'
                : 'fill'
              : 'responsive'
          }
          {...(layout && {
            ratio: layout === IMAGE_SIZE.SMALL ? css.ratio : null,
          })}
          sizesFromBreakpoints={layout ? IMAGE_SIZES?.[layout] : [{ ratio: 1 }]}
          {...(layout === IMAGE_SIZE.LARGE ? {} : { objectFit: 'cover' })}
        />
      )}
      {withActiveLayout && (
        <div
          onClick={() => {
            setActivePin(null)
          }}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            backgroundColor: '#000000',
            transition: 'opacity 0.15s ease-in',
            opacity: numberAsBoolean(activePin) ? 0.2 : 0,
          }}
        />
      )}
      {Array.isArray(pins) &&
        loadedBackground &&
        pinsElement?.map((child, index) =>
          React.cloneElement(child, {
            className: child?.props?.className,
            key: `item_${index}`,
            onClick: () => {
              child?.props?.onClick?.()
              setActivePin(activePin === index ? null : index)
            },
            activePin,
            index,
          }),
        )}
    </div>
  )
}

ProductPins.defaultProps = {}

export default ProductPins

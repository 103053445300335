import classnames from 'classnames/bind'
import React from 'react'
import { Card } from '~/@types/card'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'

import {
  DeviceType,
  useDeviceType,
  useIsHover,
} from '@unlikelystudio/react-hooks'

import RichText from '~/components/Abstracts/RichText'
import Video from '~/components/Abstracts/Video'
import WrapperWithLink from '~/components/Abstracts/WrapperWithLink'
import Image from '~/components/UI/Image'
import ImageHover from '~/components/UI/ImageHover'
import InlineCta from '~/components/UI/InlineCta'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export const CardGenericLayoutType = {
  BIG: 'big',
  REGULAR: 'regular',
} as const

type CardGenericLayoutType =
  typeof CardGenericLayoutType[keyof typeof CardGenericLayoutType]

const IMAGE_SIZES = {
  [CardGenericLayoutType.BIG]: [
    { breakpoint: 'md', ratio: 930 / 1440 },
    { ratio: 375 / 415 },
  ],
  [CardGenericLayoutType.REGULAR]: [
    { breakpoint: 'md', ratio: 460 / 1440 },
    { ratio: 375 / 415 },
  ],
}

export type GenericCardProps = Card & {
  className?: string
  layout?: CardGenericLayoutType
  theme?: typeof GlobalThemeColors.White | typeof GlobalThemeColors.Black
  videoSrc?: string
}

function GenericCard({
  className,
  name,
  link,
  image,
  imageHover,
  layout,
  priority,
  theme,
  videoSrc,
}: GenericCardProps) {
  const [isHovered, callbacks] = useIsHover()

  const device = useDeviceType()
  const titleStyle = useStyle({
    textPreset:
      layout === CardGenericLayoutType.BIG
        ? GlobalTextPreset.Title20_24SangBleuRegular
        : GlobalTextPreset.Title20_18SangBleu,
  })

  const isHovering = device === DeviceType.mobile ? false : isHovered
  return (
    <WrapperWithLink
      {...link}
      {...callbacks}
      className={cx(css.GenericCard, className, css?.[`layout-${layout}`])}>
      {image && (
        <Image
          {...image}
          sizesFromBreakpoints={IMAGE_SIZES[layout]}
          layout="fill"
          objectFit="cover"
          className={css.image}
          asPlaceholder
          priority={priority}
        />
      )}

      {videoSrc && (
        <Video
          src={videoSrc}
          className={css.video}
          poster={image?.src}
          loop
          autoPlay
          muted
          playsInline
          loading="lazy"
          preload="metadata"
        />
      )}

      {imageHover && (
        <ImageHover
          className={cx(css.hoverImage)}
          isHovering={isHovering}
          sizesFromBreakpoints={IMAGE_SIZES[layout]}
          layout="fill"
          objectFit="cover"
          draggable={false}
          priority={priority}
          {...imageHover}
        />
      )}

      <div className={css.content}>
        {name && (
          <RichText
            className={cx(css.title, titleStyle, theme)}
            render={name}
          />
        )}
        {link && (
          <InlineCta
            {...link}
            href={null}
            isDiv
            lineProps={{ initialLineState: isHovered ? 'underlined' : 'none' }}
            className={css.cta}
            textPreset={GlobalTextPreset.Cta12Grotesk}
            theme={theme}
          />
        )}
      </div>
    </WrapperWithLink>
  )
}

GenericCard.defaultProps = {
  layout: CardGenericLayoutType.BIG,
}

export default GenericCard

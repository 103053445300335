import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { LinkProps } from '@unlikelystudio/react-abstract-components'

import RichText, { RichTextBlock } from '~/components/Abstracts/RichText'
import InlineCta from '~/components/UI/InlineCta'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type PushTitleLinkProps = {
  className?: string
  title?: RichTextBlock
  link?: LinkProps
  label?: RichTextBlock
  theme?: typeof GlobalThemeColors.White | typeof GlobalThemeColors.Concrete
  withDesktopPaddings?: boolean
}

function PushTitleLink({
  className,
  title,
  link,
  label,
  withDesktopPaddings,
  theme,
}: PushTitleLinkProps) {
  const titleStyle = useStyle({ textPreset: GlobalTextPreset.Title40SangBleu })
  const labelStyle = useStyle({
    textPreset: GlobalTextPreset.Cta12Grotesk,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <div
      className={cx(
        css.PushTitleLink,
        className,
        theme && withDesktopPaddings ? css?.[`theme-${theme}`] : null,
        { withPaddings: withDesktopPaddings },
      )}>
      {title && (
        <RichText render={title} className={cx(titleStyle, css.title)} />
      )}
      {label && (
        <RichText render={label} className={cx(css.label, labelStyle)} />
      )}
      {link && (
        <InlineCta
          {...link}
          textPreset={GlobalTextPreset.Cta12Grotesk}
          theme={GlobalThemeColors.Black}
          className={css.cta}
          lineProps={{
            initialLineState: 'underlined',
          }}
        />
      )}
    </div>
  )
}

PushTitleLink.defaultProps = {}

export default PushTitleLink

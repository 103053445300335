import classnames from 'classnames/bind'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'

import { PinPosition, PinProps } from '~/components/Abstracts/ProductsPins'
import { CloseIcon } from '~/components/UI/Icons'
import ProductPinTag, {
  ProductPinTagProps,
} from '~/components/UI/ProductPinTag'

import { useStyle } from '~/providers/StyleProvider'

import { CTA } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type ProductPinTag = PinProps & {
  product?: ProductPinTagProps
  theme?: typeof GlobalThemeColors.Black | typeof GlobalThemeColors.White
  activePin?: number
  index?: number
  onClick?(): void
}

export type ProductPinToggleProps = {
  className?: string
  theme?: typeof GlobalThemeColors.Black | typeof GlobalThemeColors.White
  position: PinPosition
  onClick?: () => void
}

function PinToggle({
  className,
  position,
  onClick,
  theme,
}: ProductPinToggleProps) {
  const t = useTranslate()
  const toggleStyle = useStyle({
    borderColor: theme,
  })

  return (
    <button
      className={cx(css.Toggle, className, toggleStyle)}
      style={{ left: `${position.x}%`, top: `${position.y}%` }}
      onClick={onClick}
      aria-label={t(CTA.CLOSE) ?? 'close'}>
      <CloseIcon className={cx(css.icon)} theme={theme} />
    </button>
  )
}

function PinMarker({
  index,
  product,
  position,
  activePin,
  theme,
  onClick,
}: ProductPinTag) {
  const isActive = activePin === index
  const horizontalHalf = Math.ceil(position?.x / (100 / 2))
  const verticalThird = Math.ceil(position?.y / (100 / 3))

  return (
    <>
      <PinToggle
        theme={theme}
        className={cx(css.toggle, { isActive })}
        position={position}
        onClick={onClick}
      />
      <div
        className={cx(css.tagContainer, css.hideOnSmallScreen)}
        style={{ left: `${position.x}%`, top: `${position.y}%` }}>
        {product && (
          <ProductPinTag
            className={cx(
              css.tag,
              css?.[`HorizontalHalf-${horizontalHalf}`],
              css?.[`VerticalThird-${verticalThird}`],
              { hidden: !isActive },
            )}
            {...product}
          />
        )}
      </div>
      <div className={cx(css.tagContainer, css.hideOnLargeScreen)}>
        <ProductPinTag
          className={cx(css.tag, { hidden: !isActive })}
          {...product}
        />
      </div>
    </>
  )
}

PinMarker.defaultProps = {}

export default PinMarker

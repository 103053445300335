import { useRouter } from 'next/router'
import { ComponentProps } from 'react'

import { PageViewAnalytics as AbstractPageViewAnalytics } from '@unlikelystudio/react-ecommerce-hooks'

export function PageViewAnalytics({
  template,
}: Pick<ComponentProps<typeof AbstractPageViewAnalytics>, 'template'>) {
  const router = useRouter()
  const pathname = `${router?.locale}${router?.pathname}`

  return <AbstractPageViewAnalytics template={template} pathname={pathname} />
}

import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'

import Image, { ImageProps } from '~/components/UI/Image'

import { useStyle } from '~/providers/StyleProvider'

import { EditoSliceProps } from '~/data/slices-data'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type ImageWithLabelProps = EditoSliceProps & {
  className?: string
  image?: ImageProps
  label?: string
  layout?: IMAGE_SIZE
}

export const IMAGE_SIZE = {
  LARGE: 'large',
  SMALL: 'small',
} as const

export type IMAGE_SIZE = typeof IMAGE_SIZE[keyof typeof IMAGE_SIZE]

export const IMAGE_SIZES = {
  [IMAGE_SIZE.LARGE]: [{ breakpoint: 'md', ratio: 2 / 3 }, { ratio: 1 }],
  [IMAGE_SIZE.SMALL]: [{ breakpoint: 'md', ratio: 1 / 3 }, { ratio: 1 }],
}

function ImageWithLabel({
  className,
  image,
  label,
  layout,
  aboveTheFold,
}: ImageWithLabelProps) {
  const labelStyle = useStyle({
    textPreset: GlobalTextPreset.Label12SangBleu,
    color: GlobalThemeColors.Black,
  })

  return (
    <figure className={cx(css.ImageWithLabel, className)}>
      {image && (
        <Image
          className={cx(css.image)}
          layout={layout === IMAGE_SIZE.LARGE ? 'responsive' : 'fill'}
          ratio={layout === IMAGE_SIZE.SMALL ? css.ratio : null}
          sizesFromBreakpoints={IMAGE_SIZES?.[layout]}
          {...(layout === IMAGE_SIZE.LARGE ? {} : { objectFit: 'cover' })}
          {...image}
          priority={aboveTheFold}
        />
      )}
      {label && (
        <figcaption className={cx(css.label, labelStyle)}>{label}</figcaption>
      )}
    </figure>
  )
}

ImageWithLabel.defaultProps = {}

export default ImageWithLabel

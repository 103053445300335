import classnames from 'classnames/bind'
import { GlobalGridPreset } from '~/@types/grid-preset'

import GenericCard, {
  CardGenericLayoutType,
  GenericCardProps,
} from '~/components/UI/GenericCard'
import PushTitleLink, {
  PushTitleLinkProps,
} from '~/components/UI/PushTitleLink'

import { useStyle } from '~/providers/StyleProvider'

import { EditoSliceProps } from '~/data/slices-data'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type PushGenericProps = EditoSliceProps & {
  className?: string
  pushTitleLink?: PushTitleLinkProps
  items?: GenericCardProps[]
}

function PushGeneric({
  className,
  pushTitleLink,
  items,
  aboveTheFold,
}: PushGenericProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const hasRegularItem = items?.length === 3

  if (!items && !pushTitleLink) return null
  return (
    <div className={cx(css.PushGeneric, className)}>
      <div className={gridStyle}>
        {pushTitleLink && (
          <PushTitleLink
            {...pushTitleLink}
            className={cx(css.pushTitle, { hasRegularItem })}
            withDesktopPaddings
          />
        )}
        {items?.map((item, index) => {
          const isRegular = hasRegularItem && index === 1

          return (
            <GenericCard
              layout={
                isRegular
                  ? CardGenericLayoutType.REGULAR
                  : CardGenericLayoutType.BIG
              }
              key={`generic_card_${index}`}
              className={cx(css.item, { isRegular })}
              priority={aboveTheFold}
              {...item}
            />
          )
        })}
      </div>
    </div>
  )
}

PushGeneric.defaultProps = {}

export default PushGeneric
